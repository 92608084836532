import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Card } from "react-bootstrap";
import { FaChartBar } from "react-icons/fa";
import Hero from "../components/sections/Hero";

import browserRedirect from "../helpers/browserRedirect";
import withI18next from "../components/withI18next";
// import { localizeLink } from "../components/Linki18n";

const AI = ({ data, pageContext: { locale, originalPath }, t }) => {

  browserRedirect(locale);

  return (
    <>
    <SEO title={t("Artificial Intelligence")} lang={locale} description={t("meta_description")}/>

    <Layout originalPath={originalPath}>
        <Hero small/>
        <section className="section">
          <Container className="my-4">
            <h2>
              <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-2">
                <FaChartBar/>
              </div>
              <span className="ml-3">{t("Artificial Intelligence")}</span>
            </h2>
          </Container>
        </section>
    </Layout>
    </>
  );
};

export default withI18next({ ns: "common" })(AI);



